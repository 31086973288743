/**
 * Updated July 2019 to support jquery 3:
 * - .height() Return values on empty sets are undefined: https://jquery.com/upgrade-guide/3.0/#breaking-change-return-values-on-empty-sets-are-undefined
 */

import FeatureFlags from 'common/feature_flags';

(function($) {
  $.fn.fatrowRenderType = function(options) {
    // Check if object was already created
    var fatrowRenderType = $(this[0]).data('fatrowRenderType');
    if (!fatrowRenderType) {
      fatrowRenderType = new FatrowRenderTypeObj(options, this[0]);
    }
    return fatrowRenderType;
  };

  var FatrowRenderTypeObj = function(options, dom) {
    this.settings = $.extend({}, FatrowRenderTypeObj.defaults, options);
    this.currentDom = dom;
    this.init();
  };

  $.extend(FatrowRenderTypeObj, {
    defaults: {
      pageSize: 20,
      view: null
    },

    prototype: {
      init: function() {
        var frObj = this;
        var $domObj = frObj.$dom();
        $domObj.data('fatrowRenderType', frObj);

        frObj.richRenderer = frObj.$template().richRenderer({
          balanceFully: true,
          columnCount: 3,
          config: ((frObj.settings.view.metadata || {}).richRendererConfigs || {}).fatRow,
          view: frObj.settings.view
        });

        frObj.navigation = frObj.$dom().find('.navigation').on('page_changed', function() {
          renderCurrentPage(frObj);
        }).navigation({
          pageSize: frObj.settings.pageSize,
          view: frObj.settings.view
        });

        hookUpHeaders(frObj);

        $domObj.on('resize', function() {
          resizeHandle(frObj);
        });

        frObj._shown = false;
        var mainUpdate = function() {
          if (!frObj._shown) {
            return;
          }
          renderHeaders(frObj);
          frObj.richRenderer.renderLayout();
          renderCurrentPage(frObj);
        };
        var rowChange = function(rows, fullReset) {

          if (FeatureFlags.value('prohibit_use_of_row_set')) {
            // noop
          } else {

            if (!frObj._shown) {
              return;
            }
            if (fullReset) {
              mainUpdate();
            } else {
              _.each(rows, function(r) {
                updateRow(frObj, r);
              });
            }
          }
        };
        var updateHeader = function() {
          var headerShown = (((frObj.settings.view.metadata || {}).richRendererConfigs || {}).
            fatRow || {}).headerShown;
          toggleHeader(frObj, $.isBlank(headerShown) || headerShown, true);
        };
        frObj.settings.view.
          bind('columns_changed', mainUpdate).
          bind('query_change', mainUpdate).
          bind('row_change', rowChange).
          bind('row_count_change', mainUpdate).
          bind('clear_temporary', updateHeader);

        frObj.$dom().on('show', function() {
          frObj._shown = true;
          resizeHandle(frObj);
          mainUpdate();
        });
        frObj.$dom().on('hide', function() {
          frObj._shown = false;
        });

        frObj.$dom().on({
          'mouseenter': function() {
            // noop
          },
          'mouseleave': function() {
            // noop
          }
        },
        '.rowList > .row');
      },

      $dom: function() {
        if (!this._$dom) {
          this._$dom = $(this.currentDom);
          if (this._$dom.children().length < 1) {
            this._$dom.append($.renderTemplate('fatRowRenderType'));
            this._$dom.addClass('fatRowRenderType navRenderType');
          }
        }
        return this._$dom;
      },

      $list: function() {
        if (!this._$list) {
          this._$list = this.$dom().find('.rowList');
        }
        return this._$list;
      },

      $template: function() {
        if (!this._$template) {
          this._$template = this.$dom().find('.templateRow');
        }
        return this._$template;
      }
    }
  });

  var resizeHandle = function(frObj) {
    frObj.$list().height(frObj.$dom().height() -
      (frObj.$list().outerHeight(true) - frObj.$list().height()) -
      (frObj.$dom().find('.columnHeaders').outerHeight(true) || 0));
    frObj.richRenderer.adjustLayout();
    var $headers = frObj.$dom().find('.columnHeaders');
    if ($headers.length > 0) {
      $headers.find('.scrollBox').toggleClass('hide',
        $headers[0].scrollHeight <= $headers.height());
    }
  };

  var hookUpHeaders = function() {
    return;
  };

  var toggleHeader = function() {
    return;
  };

  var renderHeaders = function() {
    return;
  };

  var renderNewRow = function(frObj, r) {
    var $item = frObj.$template().clone().removeClass('templateRow');
    frObj.richRenderer.renderRow($item, r);
    frObj.$list().append($item);
  };

  var renderCurrentPage = function(frObj) {
    frObj.$list().empty();

    if ($.isBlank(frObj.navigation.currentPage()) || frObj.settings.view.totalRows() < 1) {
      frObj.$list().append($.tag({
        tagName: 'div',
        'class': 'noResults',
        contents: $.t('controls.grid.no_rows')
      }));
      return;
    }

    var rowsLoaded = function(rows) {
      _.each(rows, function(r) {
        updateRow(frObj, r);
      });
    };

    var delay = 500;
    var loadRows;
    loadRows = function() {
      frObj.settings.view.getRows(frObj.navigation.currentPage() *
        frObj.settings.pageSize, frObj.settings.pageSize, rowsLoaded,
        function() {
          setTimeout(loadRows, delay);
          delay *= 2;
        });
    };
    loadRows();
  };

  var updateRow = function(frObj, row) {
    var cp = frObj.navigation.currentPage();
    var realRow;

    if (FeatureFlags.value('prohibit_use_of_row_set')) {
      realRow = row;
    } else {
      realRow = frObj.settings.view.rowForID(row.id);
    }

    if ($.isBlank(cp) || (!$.isBlank(realRow) &&
        (realRow.index < cp * frObj.settings.pageSize ||
          realRow.index >= (cp + 1) * frObj.settings.pageSize))) {
      return;
    }

    var foundRow = false;
    frObj.$list().children('.row').each(function() {
      var $r = $(this);

      if ($r.data('renderrow').id == row.id) {
        if (!$.isBlank(realRow)) {
          frObj.richRenderer.renderRow($r, realRow);
        } else {
          $r.remove();
        }
        foundRow = true;
        return false;
      }
    });

    if (!foundRow && !$.isBlank(realRow)) {
      renderNewRow(frObj, realRow);
    }
  };

})(jQuery);
