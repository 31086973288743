import SoqlDataProvider from 'common/visualizations/dataProviders/SoqlDataProvider';
import DataTypeFormatter from 'common/DataTypeFormatter';


(function($) {
  $.fn.pageRenderType = function(options) {
    // Check if object was already created
    var pageRenderType = $(this[0]).data('pageRenderType');
    if (!pageRenderType) {
      pageRenderType = new PageRenderTypeObj(options, this[0]);
    }
    return pageRenderType;
  };

  var PageRenderTypeObj = function(options, dom) {
    this.settings = $.extend({}, PageRenderTypeObj.defaults, options);
    this.currentDom = dom;
    this.init();
  };

  $.extend(PageRenderTypeObj, {
    defaults: {
      defaultRowId: null,
      view: null
    },

    prototype: {
      init: function() {
        var prtObj = this;
        var $domObj = prtObj.$dom();
        $domObj.data('pageRenderType', prtObj);

        const newBackend = _.get(prtObj, 'settings.view.newBackend');

        // TODO: 100% NBE - Remove richRenderer and Navigation
        if (!newBackend) {
          prtObj.richRenderer = prtObj.$content().richRenderer({
            columnCount: 2,
            config: ((prtObj.settings.view.metadata || {}).richRendererConfigs || {}).page,
            view: prtObj.settings.view
          });
        }

        $domObj.on('resize', function() {
          resizeHandle(prtObj);
        });

        prtObj.navigation = $domObj.find('.navigation').on('page_changed', function(e, userInteraction) {
          renderCurrentRow(prtObj, userInteraction);
        }).navigation({
          pageSize: 1,
          view: prtObj.settings.view
        });

        prtObj._shown = false;

        var mainUpdate = function() {
          if (!prtObj._shown) {
            return;
          }

          // TODO: 100% NBE - Remove richRenderer and Navigation
          if (!newBackend) {
            prtObj.richRenderer.renderLayout();
          }
          renderCurrentRow(prtObj);
        };

        var rowsChanged = function() {
          // Need to re-find current row ID
          if (!$.isBlank(prtObj._currentRowId)) {
            prtObj.displayRowByID(prtObj._currentRowId);
          } else {
            renderCurrentRow(prtObj);
          }
        };

        var rowChange = function(rows, fullReset) {
          if (!prtObj._shown) {
            return;
          }
          if (fullReset) {
            mainUpdate();
          } else {
            var cp = prtObj.navigation.currentPage();
            if ($.isBlank(cp)) {
              return;
            }
            _.each(rows, function(r) {
              var realRow = prtObj.settings.view.rowForID(r.id);
              if (!$.isBlank(realRow) && realRow.index == cp) {
                renderCurrentRow(prtObj);
              }
            });
          }
        };

        // Expect row data like ['abc', 123, 'g'] where each index is a separate column
        // Note this does not use the rich-renderer, and instead just updates the DOM
        // structure found in _page_render_type.erb
        var updateSingleRowHTML = function(currentSingleRowDOM, rowData = [], columns = []) {
          let columnsDOM = _.toArray(currentSingleRowDOM.getElementsByClassName('pageLine'));
          columnsDOM.forEach(function(colDOM, idx) {
            let valueDOM = colDOM.getElementsByClassName('pageItem')[0];

            if (rowData[idx]) {
              $(valueDOM).html(
                DataTypeFormatter.renderCellHTML(rowData[idx], columns[idx])
              );
            }
          });
        };

        prtObj.settings.view.
          bind('columns_changed', mainUpdate).
          bind('query_change', rowsChanged).
          bind('row_change', rowChange).
          bind('row_count_change', rowsChanged).
          bind('row_clicked', updateSingleRowHTML);

        prtObj.$dom().on('show', function() {
          prtObj._shown = true;
          resizeHandle(prtObj);
          mainUpdate();
        });
        prtObj.$dom().on('hide', function() {
          prtObj._shown = false;
        });

        $(document).on(blist.events.DISPLAY_ROW, function(e, rowId) {
          var sameDS = true;
          if (typeof rowId == 'string' && rowId.indexOf('/') > -1) {
            var splitRowId = rowId.split('/');
            sameDS = splitRowId[0] == prtObj.settings.view.id || splitRowId[0] == blist.dataset.id;
            rowId = splitRowId[1];
          }

          if (sameDS && !$.isBlank(rowId)) {
            prtObj.displayRowByID(rowId);
          }
        });

        if (!$.isBlank(prtObj.settings.defaultRowId)) {
          prtObj.displayRowByID(prtObj.settings.defaultRowId);
        }
      },

      $dom: function() {
        if (!this._$dom) {
          this._$dom = $(this.currentDom);
          if (this._$dom.children().length < 1) {
            this._$dom.append($.renderTemplate('pageRenderType'));
            this._$dom.addClass('pageRenderType navRenderType');
          }
        }
        return this._$dom;
      },

      $content: function() {
        if (!this._$content) {
          this._$content = this.$dom().find('.content');
        }
        return this._$content;
      },

      $noResults: function() {
        if (!this._$noResults) {
          this.$dom().append($.tag({
            tagName: 'div',
            'class': 'noResults',
            contents: $.t('controls.grid.no_rows')
          }));
          this._$noResults = this.$dom().find('.noResults');
        }
        return this._$noResults;
      },

      displayRowByID: function(rowId) {
        var prtObj = this;

        prtObj._currentRowId = rowId;
        prtObj.settings.view.rowIndex(rowId, function(rowIndex) {
          if ($.isBlank(rowIndex)) {
            rowIndex = 0;
          }
          prtObj.navigation.displayPage(rowIndex);
        });
      }
    }
  });

  var resizeHandle = function(prtObj) {
    const newBackend = _.get(prtObj, 'settings.view.newBackend');
    prtObj.$content().height(prtObj.$dom().height() -
      (prtObj.$content().outerHeight(true) - prtObj.$content().height()));

    // TODO: 100% NBE - Remove richRenderer and Navigation
    if (!newBackend) {
      prtObj.richRenderer.adjustLayout();
    }
  };

  var generateSingleRowHTML = function(row = {}, columns = []) {
    const pageColumn = document.createElement('div');
    pageColumn.classList.add('pageColumn');

    columns.forEach((col) => {
      let pageLine = document.createElement('div');
      pageLine.classList.add('pageLine');
      pageLine.classList.add(col.renderTypeName);

      let pageLabel = document.createElement('span');
      pageLabel.classList.add('pageLabel');
      pageLabel.textContent = col.fieldName;

      let pageItem = document.createElement('div');
      pageItem.classList.add('pageItem');
      $(pageItem).html(
        DataTypeFormatter.renderCellHTML(row[col.fieldName], col)
      );

      pageLine.append(pageLabel, pageItem);
      pageColumn.append(pageLine);
    });

    return pageColumn;
  };

  var renderCurrentRow = function(prtObj, updateId) {
    const newBackend = _.get(prtObj, 'settings.view.newBackend');

    // TODO: 100% NBE - Remove richRenderer and Navigation
    if (!newBackend) {
      if ($.isBlank(prtObj.navigation.currentPage()) || prtObj.settings.view.totalRows() < 1) {
        prtObj.$content().hide();
        prtObj.$noResults().show();
        return;
      }

      prtObj.$content().show();
      prtObj.$noResults().hide();

      var rowLoaded = function(rows) {
        if (rows.length != 1) {
          return;
        }
        var row = rows[0];

        if (updateId) {
          prtObj._currentRowId = row.id;
        }

        prtObj.richRenderer.renderRow(prtObj.$content(), row, true);
        prtObj.richRenderer.adjustLayout();
      };
      var delay = 500;
      var loadRows;
      loadRows = function() {
        prtObj.settings.view.getRows(prtObj.navigation.currentPage(), 1, rowLoaded,
          function() {
            setTimeout(loadRows, delay);
            delay *= 2;
          });
      };
      loadRows();
    } else {
      // If defaultRowId is null, this likely means that the user clicked the
      // single-row view after loading the regular grid view, and did not navigate
      // to the page with `/4x4/row-id`.
      // In this case the JS needs to build up the DOM found in _page_render_type.erb
      // and populate it with the data from the first row, because it was not pre-populated
      // via Rails.
      if (_.isNil(prtObj.settings.defaultRowId) && _.isNil(prtObj._currentRowId)) {
        const uid = _.get(prtObj, 'settings.view.id');
        const columns = _.get(prtObj, 'settings.view.columns', []);

        const soqlDataProvider = new SoqlDataProvider({ domain: window.location.hostname, datasetUid: uid });
        const request = soqlDataProvider.rawQuery('select *,:id limit 1');

        request.then((response) => {
          let row = response[0];
          prtObj._currentRowId = row[':id'];
          let singleRowHTML = generateSingleRowHTML(row, columns);
          prtObj.$content()[0].append(singleRowHTML);
        });
      }
    }
  };

})(jQuery);
